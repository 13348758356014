export default function (container) {
    var isBrandChamp = container.querySelector('.brand--champ');
    if (isBrandChamp) {
        var bgImgIcons = isBrandChamp.getElementsByClassName('flag-icon');
        var imgTagIcons = isBrandChamp.getElementsByTagName('img');

        if (bgImgIcons.length > 0) {
            bgImgIcons.forEach(function(el) {
                el.classList.add('u-bg-contain');
            });
        }

        if (imgTagIcons.length > 0) {
            imgTagIcons.forEach(function(el) {
                el.classList.remove('u-object-cover');
                el.classList.add('u-object-contain');
            });
        }
    }
}