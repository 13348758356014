import $j from 'jquery';

export default function (container) {
	Promise.all($j(container).find('[data-require]').toArray().map((el) => {
		return new Promise((resolve, reject) => {
			const $el = $j(el),
				data = $el.data(),
				ref = data.require;
			window.loadjs([ref], (mod) => {
				if (mod) {
					const instance = mod.default();

					instance.init($el, data);

					resolve(instance);
				}
				else {
					reject(`Failed to load module ${ ref }`);
				}
			});
		});
	})).then((modules) => {
	}).catch((err) => {
		console.error(err);
	});
}